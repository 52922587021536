<template>
  <div>
    <!-- SECTION - BANNER -->
    <base-breadcrumb :pages="pages"></base-breadcrumb>
    <div class="banner">
      <ByteArkPlayerContainer
        v-if="bannerType === 'live'"
        :options="playerOptions"
      />
      <!-- <div class="video-container">
        <iframe
          class="video-player"
          src="https://www.youtube.com/embed/5qap5aO4i9A"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div> -->
      <carousel
        v-if="bannerType === 'image'"
        :perPage="1"
        loop
        autoplay
        :autoplayHoverPause="false"
        :autoplayTimeout="4000"
      >
        <slide v-for="banner in bannerList" :key="banner.banner_id">
          <div
            class="mx-auto slide-container"
            @click="goToBannerLink(banner.href)"
          >
            <div class="thumbnail-container">
              <img v-if="banner.url" :src="banner.url" alt="" />
              <div v-else></div>
            </div>
          </div>
        </slide>
      </carousel>
      <!-- <div class="promotion">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <h2 class="mr-3">เข้าร่วมหลักสูตร</h2>
                <div>
                  <img class="logo" src="../assets/images/Sponga_Logo.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <p class="text-big">
                แหล่งความรู้ทางด้านการเงินสำหรับประชาชนทั่วไป<br
                  class="d-none d-md-block"
                />
                และนักศึกษาแบบครบวงจร เพื่อหาความรู้ในการพัฒนาตนเอง
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="pl-3" style="border-left: 7px solid #07C5F8">
                <h4 class="color-secondary">
                  เริ่มสมัครคอร์สเรียนวันนี้ 787 THB / Monthly
                </h4>
                <h2 class="color-secondary">
                  ลดทันที 30% โปรโมชั่นนี้ ภายในวันนี้
                </h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="d-flex my-3 subscribe text-big">
                <input type="email" placeholder="email address" />
                <button>Subscribe</button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- SECTION - All Courses -->
    <div class="container">
      <div class="filter">
        <div class="row align-items-center">
          <div class="col-12 col-lg-3">
            <div class="d-flex align-items-center">
              <div class="position-relative  w-100" style="margin-right: 10px">
                <input
                  type="text"
                  placeholder="search"
                  class="w-100"
                  v-model="searchTerm"
                  v-debounce:500ms="queryCourse"
                />
                <img
                  class="position-absolute"
                  style="right: 10px; top:50%; transform: translateY(-50%)"
                  src="../assets/images/search.svg"
                  alt=""
                />
              </div>
              <img
                class="d-block d-lg-none"
                style="cursor: pointer;"
                src="../assets/images/filter.svg"
                alt=""
                @click="toggleFilter"
              />
            </div>
          </div>
          <div
            class="col-12 col-lg-9 checkbox-container"
            :class="{ 'show-filter': showFilter }"
          >
            <div
              class="d-flex flex-wrap flex-column flex-lg-row justify-content-between mt-3 mt-lg-0"
            >
              <CustomCheckbox
                label="All"
                :checked="allCourse"
                @toggleCheck="toggleCheckbox"
              />
              <CustomCheckbox
                label="Basic"
                :checked="basicCourse"
                @toggleCheck="toggleCheckbox"
              />
              <CustomCheckbox
                label="Intermediate"
                :checked="intermediateCourse"
                @toggleCheck="toggleCheckbox"
              />
              <CustomCheckbox
                label="Advance"
                :checked="advanceCourse"
                @toggleCheck="toggleCheckbox"
              />
            </div>
          </div>
        </div>
      </div>
      <h4 class="font-weight-bold mr-3 mb-3">
        คอร์สทั้งหมดใน {{ curriculumName }}
      </h4>
      <div v-if="courseList.length > 0" class="row">
        <div
          v-for="course in courseList"
          :key="course.id"
          class="col-12 col-md-4 my-2"
        >
          <CourseCard :course="course" type="course" />
        </div>
      </div>
      <div v-else>
        <h3 class="color-text text-center">ไม่มีคอร์สที่จะแสดง</h3>
      </div>
      <p
        v-if="courseTotal > courseList.length"
        class="see-more color-secondary text-big font-weight-bold"
        @click="loadMoreCourse"
      >
        ดูเพิ่มเติม
      </p>
    </div>
  </div>
</template>

<script>
import ByteArkPlayerContainer from "@byteark/byteark-player-vue";
import CourseCard from "../components/CourseCard.vue";
import CustomCheckbox from "../components/CustomCheckbox.vue";
import BaseBreadcrumb from "../components/BaseBreadcrumb.vue";
import axios from "../api/faAPI";
import baseUrl from "../util/backend";
import Qs from "qs";
export default {
  components: {
    CourseCard,
    CustomCheckbox,
    BaseBreadcrumb,
    ByteArkPlayerContainer,
  },
  data() {
    return {
      curriculumName: "",
      bannerList: null,
      bannerType: "image",
      courseList: [],
      page: 1,
      courseTotal: 0,
      // ----- for filter ---- //
      searchTerm: "",
      allCourse: true,
      basicCourse: false,
      intermediateCourse: false,
      advanceCourse: false,
      showFilter: false,
      // <<-- for filter -->> //
      playerOptions: {
        autoplay: true,
        fluid: true,
        aspectRatio: "16:9",
        // poster:
        //   "https://qoder.byteark.com/images/video-frames/1/GU/cg/1GUcgd3XwsmD-large.jpg",
        sources: {
          src: "",
          type: "application/x-mpegURL",
          // Optional
          title: "LIVE",
          videoId: "RI2PimuHxDXw",
          // poster:
          //   "https://qoder.byteark.com/images/video-frames/1/GU/cg/1GUcgd3XwsmD-large.jpg",
        },
      },
    };
  },
  watch: {
    "$route.query.filter"() {
      this.setFilter();
    },
    filterArr() {
      console.log(this.filterArr);
    },
  },
  computed: {
    pages() {
      return [
        {
          name: "หลักสูตรทั้งหมด",
          link: "/curriculum",
        },
        {
          name: this.curriculumName,
          link: "",
        },
      ];
    },
    filterArr() {
      let arr = [];
      if (this.basicCourse) {
        arr.push("Basic");
      }
      if (this.intermediateCourse) {
        arr.push("Intermediate");
      }
      if (this.advanceCourse) {
        arr.push("Advance");
      }
      return arr.length > 0 ? arr : undefined;
    },
  },
  methods: {
    toggleCheckbox(value, name) {
      switch (name) {
        case "All":
          this.$router.replace({
            name: "CurriculumDetail",
            params: { curriculumId: this.$route.params.curriculumId },
          });
          break;
        case "Basic":
          this.basicCourse = value;
          this.$router.replace({
            name: "CurriculumDetail",
            query: { filter: this.filterArr },
          });
          break;
        case "Intermediate":
          this.intermediateCourse = value;
          this.$router.replace({
            name: "CurriculumDetail",
            query: { filter: this.filterArr },
          });
          break;
        case "Advance":
          this.advanceCourse = value;
          this.$router.replace({
            name: "CurriculumDetail",
            query: { filter: this.filterArr },
          });
          break;
        default:
        // code block
      }
    },
    setFilter() {
      this.allCourse = false;
      this.basicCourse = false;
      this.intermediateCourse = false;
      this.advanceCourse = false;
      if (this.$route.query.filter) {
        if (this.$route.query.filter.includes("Basic")) {
          this.allCourse = false;
          this.basicCourse = true;
        }
        if (this.$route.query.filter.includes("Intermediate")) {
          this.allCourse = false;
          this.intermediateCourse = true;
        }
        if (this.$route.query.filter.includes("Advance")) {
          this.allCourse = false;
          this.advanceCourse = true;
        }
      } else {
        this.allCourse = true;
        this.basicCourse = false;
        this.intermediateCourse = false;
        this.advanceCourse = false;
      }
      this.queryCourse();
    },
    queryCourse() {
      console.log("queryCourse");
      this.page = 1;
      this.courseList = [];
      this.loadCourse();
    },
    goToBannerLink(link) {
      window.open(`${link}`, "_blank");
    },
    async loadCourse() {
      if (this.searchTerm || this.filterArr) {
        try {
          const res = await axios.get(`${baseUrl}/courseFilter`, {
            paramsSerializer: (params) =>
              Qs.stringify(params, { arrayFormat: "repeat" }),
            params: {
              page: this.page,
              limit: 9,
              curriculum_id: this.$route.params.curriculumId,
              name: this.searchTerm ? this.searchTerm : "",
              level: this.filterArr,
            },
          });
          console.log(res.data);
          this.curriculumName = res.data.curriculum_name;
          this.courseList = [...this.courseList, ...res.data.data];
          this.courseTotal = res.data.count;
        } catch (err) {
          if (err.response) {
            alert(err.response.data.error || "error");
          } else {
            alert(err);
          }
        }
      } else {
        try {
          const res = await axios.get(`${baseUrl}/getCurriculum`, {
            params: {
              curriculum_id: this.$route.params.curriculumId,
              page: this.page,
              limit: 9,
            },
          });
          this.curriculumName = res.data.curriculum_name;
          this.courseList = [...this.courseList, ...res.data.data];
          this.courseTotal = res.data.count;
        } catch (err) {
          if (err.response) {
            alert(err.response.data.error || "error");
          } else {
            alert(err);
          }
        }
      }
    },
    async loadMoreCourse() {
      this.page += 1;
      this.loadCourse();
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    async loadBanner() {
      try {
        const res = await axios.get(`${baseUrl}/getBanner`);
        this.bannerType = res.data.type;
        if (res.data.type === "image") {
          this.bannerList = res.data.data;
        }
        if (res.data.type === "live") {
          this.playerOptions.sources.src = res.data.data.url;
        }
      } catch (err) {
        if (err.response) {
          alert(err.response.data.error || "error");
        } else {
          alert(err);
        }
      }
    },
  },
  created() {
    this.setFilter();
    this.loadBanner();
  },
};
</script>

<style scoped>
a,
a:hover {
  color: inherit;
  text-decoration: none;
}

.thumbnail-container {
  cursor: pointer;
  position: relative;
  width: 100%;
  padding-top: 75%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.thumbnail-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
}

.banner {
  margin-bottom: 50px;
}

.banner >>> .VueCarousel-dot-container[style] {
  margin: 0 !important;
}
.banner >>> .VueCarousel-dot.VueCarousel-dot--active[style] {
  background-color: #07c5f8 !important;
  outline: none;
}
.banner >>> .VueCarousel-dot {
  background-color: #c4c4c4 !important;
  outline: none;
  margin: 0 !important;
}

.filter {
  margin: 50px 0;
}

.filter input[type="text"] {
  outline: none;
  border: 1px solid #798da5;
  padding: 10px;
}

.filter input[type="checkbox"] {
  width: 25px;
  height: 25px;
  vertical-align: middle;
}

.checkbox-container {
  height: 0;
  overflow: hidden;
  transition: all 1s;
}

.show-filter {
  height: auto;
}

.see-more {
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  margin: 10px;
}

@media only screen and (min-width: 768px) {
  /* .slide-container {
    max-width: 700px;
  }
  .banner >>> .VueCarousel-slide {
    padding: 30px 0;
  } */
  .thumbnail-container {
    cursor: pointer;
    position: relative;
    width: 100%;
    padding-top: 33.33%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }

  .banner >>> .VueCarousel-wrapper {
    background: linear-gradient(90deg, #bfeaf5 0%, #ecf1ff 75.52%);
  }
}

@media only screen and (min-width: 992px) {
  .checkbox-container {
    height: auto;
    overflow: hidden;
  }
}
</style>
